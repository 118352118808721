import React from 'react';

export const KofiTop = `
  <div class="kofi-top-minimal">
    <p>
      Does this article help you? If so, please consider supporting me with a coffee ☕️
    </p>
    <a href='https://ko-fi.com/S6S11O6W5' target='_blank' rel="noopener noreferrer"><img height='36' style={{border:'0px',height:'36px'}} src='https://cdn.ko-fi.com/cdn/kofi3.png?v=2' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>
  </div>
`;

const Kofi = () => {
  return (
    <div>
      <p>
        Wow, you read the <strong>whole</strong> article! You know, people who make it this far are true learners.
        And clearly, you value learning. Would you like to learn more about Excel?
        Please consider supporting me by buying me a coffee (it takes a lot of coffee to write these articles!).
      </p>
      <a href='https://ko-fi.com/S6S11O6W5' target='_blank' rel="noopener noreferrer"><img height='36' style={{border:'0px',height:'36px'}} src='https://cdn.ko-fi.com/cdn/kofi3.png?v=2' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>
    </div>
  );
};

export default Kofi;
