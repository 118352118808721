import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Kofi from "../components/kofi"
import { KofiTop } from "../components/kofi"
import Img from 'gatsby-image';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { DiscussionEmbed } from "disqus-react"

const insertKofi = (html) => {
  const regex = /<h2>/g
  const found = [...html.matchAll(regex)]

  if (found.length === 0) {
    return html
  }

  const insertionPoint = found.length > 1 ? found[1] : found[0]

  return `${html.slice(0, insertionPoint.index)}${KofiTop}${html.slice(insertionPoint.index)}`
}

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;
  const htmlWithAd = insertKofi(post.html);

  const disqusConfig = {
    shortname: 'spreadsheetsmadeeasy',
    config: {
      identifier: post.frontmatter.slug,
      url: post.frontmatter.link,
      title: post.frontmatter.title,
    },
  };

  const [showComments, setShowComments] = useState(false);

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        imageUrl={post.frontmatter?.featuredImage?.publicURL}
      />
      <article>
        <header>
          { post.frontmatter.featuredImage
            &&
            <Img sizes={post.frontmatter.featuredImage.childImageSharp.sizes}
            />
          }
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: rhythm(1 / 4),
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
        </header>
        <section dangerouslySetInnerHTML={{ __html: htmlWithAd }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Kofi />
          <Bio />

          { !showComments &&
            <button
              style={{
                marginBottom: rhythm(1),
              }}
              className="btn btn-primary full-width"
              onClick={() => setShowComments(true)}>
                Show Comments
            </button>
          }
          { showComments && <DiscussionEmbed {...disqusConfig} /> }
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        slug
        link
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 1920) {
              ...GatsbyImageSharpSizes
            }
          }
          publicURL
        }
      }
    }
  }
`
